<template>
  <c-autocomplete
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_OPERATING_SYSTEMS_SELECT"
    :value="operatingSystemsIds"
    :items="operatingSystems"
    :loading="operatingSystemsAreLoading"
    :label="$t('traffic_chart.os')"
    :placeholder="$t('traffic_chart.os')"
    item-text="name"
    item-value="id"
    clearable
    multiple
    hide-details
    :search-input="search"
    @change="setOperatingSystemsIds($event)"
    @update:search-input="setSearch($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'OperatingSystems',
    components: { CAutocomplete },
    data() {
      return {
        search: '',
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['operatingSystemsIds']),
      ...mapState('collections', ['operatingSystemsAreLoading', 'operatingSystems'])
    },
    created() {
      this.fetchOperatingSystemsCollection()
    },
    methods: {
      ...mapActions('trafficChart', ['setOperatingSystemsIds']),
      ...mapActions('collections', ['fetchOperatingSystemsCollection']),
      setSearch(value) {
        this.search = value
      }
    }
  }
</script>
