<template>
  <c-autocomplete
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_COUNTRIES_SELECT"
    :value="countriesIds"
    :label="$t('traffic_chart.countries')"
    :placeholder="$t('traffic_chart.countries')"
    :items="countries"
    :loading="countriesAreLoading"
    item-text="name"
    item-value="id"
    clearable
    multiple
    hide-details
    :search-input="search"
    @change="setCountriesIds($event)"
    @update:search-input="setSearch($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'Countries',
    components: { CAutocomplete },
    data() {
      return {
        search: '',
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['countriesIds']),
      ...mapState('collections', ['countriesAreLoading', 'countries'])
    },
    created() {
      this.fetchCountriesCollection()
    },
    methods: {
      ...mapActions('trafficChart', ['setCountriesIds']),
      ...mapActions('collections', ['fetchCountriesCollection']),
      setSearch(value) {
        this.search = value
      }
    }
  }
</script>
