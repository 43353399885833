<template>
  <c-autocomplete
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_PREMIUM_SITES_SELECT"
    :value="premiumSitesIds"
    :items="premiumSitesByFormat"
    :label="$t('traffic_chart.premium_sites')"
    :loading="premiumSitesIsLoading"
    item-value="id"
    :item-text="adNetwork.showSiteDomains ? 'domain' : 'id'"
    :placeholder="placeholderText"
    :disabled="selectIsDisabled"
    multiple
    clearable
    hide-details
    @change="selectPremiumSitesIds($event)"
  />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CAutocomplete from '@clickadilla/components/ui/CAutocomplete.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'TrafficPremiumSites',
    components: { CAutocomplete },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapGetters('trafficChart', ['premiumSitesByFormat']),
      ...mapState('trafficChart', ['premiumSitesIds', 'premiumSitesIsLoading', 'isPremiumSites']),
      ...mapState('settings', ['adNetwork']),
      placeholderText() {
        return this.premiumSitesByFormat.length === 0 ? this.$t('traffic_chart.no_data_available') : ''
      },
      selectIsDisabled() {
        return this.premiumSitesByFormat.length === 0 || !this.isPremiumSites
      }
    },
    methods: {
      ...mapActions('trafficChart', ['setPremiumSitesIds']),
      selectPremiumSitesIds(ids) {
        this.setPremiumSitesIds(ids)
      }
    }
  }
</script>
