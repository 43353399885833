<template>
  <c-select
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_AD_FORMATS_SELECT"
    :value="adFormatId"
    :items="getAdFormatsByAdNetwork"
    :loading="loading"
    :label="$t('traffic_chart.ad_formats')"
    :placeholder="$t('traffic_chart.ad_formats')"
    item-text="name"
    item-value="id"
    hide-details
    @change="selectFormatId($event)"
  />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'AdFormat',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('settings', ['loading']),
      ...mapGetters('trafficChart', ['adFormatId']),
      ...mapGetters('settings', ['getAdFormatsByAdNetwork'])
    },
    methods: {
      ...mapActions('trafficChart', ['setAdFormatId']),
      selectFormatId(formatId) {
        this.setAdFormatId(formatId)
      }
    }
  }
</script>
