<template>
  <div>
    <div class="text-h6 font-weight-bold">
      {{ $t('traffic_chart.traffic_chart') }}
    </div>
    <div class="text-caption black--text opacity-60">
      {{ $t('traffic_chart.chart_desc') }}
    </div>
    <settings-traffic-chart class="my-3" />
    <traffic-table class="my-3" />
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  import SettingsTrafficChart from './SettingsTrafficChart/Index.vue'
  import TrafficTable from './TrafficTable/Index.vue'

  export default {
    name: 'TrafficChart',
    components: {
      SettingsTrafficChart,
      TrafficTable
    },
    created() {
      this.applySettingsFromQuery()
    },
    methods: {
      ...mapActions('trafficChart', ['setAdFormatId', 'setPricingModel']),
      applySettingsFromQuery() {
        if (this.$route.query.adFormatId) {
          this.setAdFormatId(Number(this.$route.query.adFormatId))
        }
        if (this.$route.query.pricingModel) {
          this.setPricingModel(this.$route.query.pricingModel)
        }
        if (this.$route.query.countriesIds) {
          this.$store.commit(
            'trafficChart/SET_COUNTRIES_IDS',
            JSON.parse(this.$route.query.countriesIds)
          )
        }
        if (this.$route.query.operatingSystemsIds) {
          this.$store.commit(
            'trafficChart/SET_OPERATING_SYSTEMS_IDS',
            JSON.parse(this.$route.query.operatingSystemsIds)
          )
        }
        if (this.$route.query.trafficTypeId) {
          this.$store.commit(
            'trafficChart/SET_TRAFFIC_TYPE_ID',
            Number(this.$route.query.trafficTypeId)
          )
        }
        if (this.$route.query.premiumSitesIds) {
          this.$store.commit('trafficChart/SET_IS_PREMIUM_SITES', true)
          this.$store.commit(
            'trafficChart/SET_PREMIUM_SITES_IDS',
            JSON.parse(this.$route.query.premiumSitesIds)
          )
        }
      }
    }
  }
</script>
