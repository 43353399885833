<template>
  <c-select
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_SITES_SELECT"
    :value="isPremiumSites"
    :items="targets"
    :label="$t('traffic_chart.sites')"
    :placeholder="$t('traffic_chart.sites')"
    item-value="value"
    :disabled="!pricingModel"
    item-text="label"
    hide-details
    @change="selectIsPremiumSites($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'TrafficSites',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['isPremiumSites', 'pricingModel']),
      targets() {
        return [
          {
            label: this.$t('traffic_chart.ron'),
            value: false
          },
          {
            label: this.$t('traffic_chart.premium'),
            value: true
          }
        ]
      }
    },
    methods: {
      ...mapActions('trafficChart', ['setIsPremiumSites']),

      selectIsPremiumSites(isPremiumSites) {
        this.setIsPremiumSites(isPremiumSites)
      }
    }
  }
</script>
