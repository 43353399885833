<template>
  <v-btn-toggle
    tile
    group
    class="d-flex justify-start justify-md-space-between flex-wrap py-2 px-3"
    @change="changeHandler($event)"
  >
    <c-btn
      v-for="letter in latinAlphabet"
      :key="letter"
      :value="letter"
      height="20"
      class="table-header-btn font-weight-black text-caption pa-0 mx-3 ma-md-0"
      :label="letter"
    />
  </v-btn-toggle>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'GroupLetterButtons',
    components: {
      CBtn
    },
    data() {
      return {
        latinAlphabet: [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z'
        ]
      }
    },
    methods: {
      changeHandler(event) {
        this.$emit('change-letter', event)
      }
    }
  }
</script>

<style lang="scss" scoped>
  .table-header-btn {
    display: block;
    max-width: 20px !important;
    min-width: 20px !important;
  }
  .v-btn-toggle--group > ::v-deep .v-btn.v-item--active {
    background-color: var(--v-primary-base) !important;
    border-bottom: 1px solid var(--v-black-base);
  }
  .v-btn-toggle--group > ::v-deep .v-btn.v-item--active:before {
    opacity: 0;
  }
  .v-btn-toggle--group > ::v-deep .v-btn.v-item--active .v-btn__content {
    color: var(--v-white-base);
    font-weight: 500;
  }
</style>
