<template>
  <c-select
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_TRAFFIC_TYPE_SELECT"
    :value="trafficTypeId"
    :items="trafficTypes"
    :loading="trafficTypesAreLoading"
    :label="$t('traffic_chart.traffic_type')"
    :placeholder="$t('traffic_chart.traffic_type')"
    item-text="name"
    item-value="id"
    clearable
    hide-details
    @change="setTrafficTypeId($event)"
  />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'TrafficType',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['trafficTypeId']),
      ...mapState('collections', ['trafficTypesAreLoading', 'trafficTypes'])
    },
    created() {
      this.fetchTrafficTypesCollection()
    },
    methods: {
      ...mapActions('trafficChart', ['setTrafficTypeId']),
      ...mapActions('collections', ['fetchTrafficTypesCollection'])
    }
  }
</script>
