<template>
  <c-select
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_TYPE_OF_AD_SELECT"
    :value="selectRotationAdType"
    :items="adTypes"
    :loading="loading"
    :label="$t('traffic_chart.ad_type')"
    :title="$t('traffic_chart.ad_type')"
    :placeholder="selectPlaceholder"
    :disabled="isDisabled"
    item-slot
    selection-slot
    hide-details
    clearable
    @change="selectInStreamAdType($event)"
  >
    <template #item="{ item }">
      {{ $t(`main.rotation_types.${item}`) }}
    </template>

    <template #selection="{ item }">
      {{ $t(`main.rotation_types.${item}`) }}
    </template>
  </c-select>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import { adFormats } from '@clickadilla/components/constants/ad-formats.js'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'InstreamAdType',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['selectRotationAdType']),
      ...mapState('settings', ['loading']),
      ...mapGetters('trafficChart', ['foundAdFormat']),
      ...mapGetters('settings', ['bannerAdTypes', 'inStreamAdTypes', 'interstitialAdTypes']),
      adTypes() {
        return (
          {
            [adFormats.BANNER]: this.bannerAdTypes,
            [adFormats.IN_STREAM_AD]: this.inStreamAdTypes,
            [adFormats.INTERSTITIAL]: this.interstitialAdTypes
          }[this.foundAdFormat && this.foundAdFormat.type] || []
        )
      },
      selectPlaceholder() {
        return this.isDisabled
          ? this.$t('traffic_chart.no_ad_type')
          : this.$t('traffic_chart.ad_type')
      },
      isDisabled() {
        return this.adTypes.length === 0
      }
    },
    watch: {
      isDisabled(val) {
        if (val) {
          this.setSelectRotationAdType(null)
        }
      }
    },
    methods: {
      ...mapActions('trafficChart', ['setSelectRotationAdType']),
      selectInStreamAdType(adType) {
        this.setSelectRotationAdType(adType)
      }
    }
  }
</script>
