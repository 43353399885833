<template>
  <v-card elevation="0">
    <v-card-title class="text-body-1 secondary font-weight-medium py-2 pl-6">
      {{ $t('traffic_chart.filters') }}
      <v-spacer />
      <c-btn
        class="primary"
        :loading="trafficChartIsLoading"
        :disabled="!updateIsAllowed"
        :label="$t('main.update')"
        @click="update()"
      />
    </v-card-title>
    <v-card-text class="pa-0">
      <v-container fluid>
        <v-row>
          <v-col cols="12" lg="4" class="pb-0 pb-lg-3">
            <ad-format class="pb-2" />
            <pricing-model class="pb-2" />
          </v-col>

          <v-col cols="12" lg="4" class="py-0 py-lg-3 px-lg-0">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                  <instream-ad-type class="pb-2" />
                </v-col>
                <v-col cols="12" sm="6" class="pa-0 pl-sm-1">
                  <traffic-type class="pb-2" />
                </v-col>
              </v-row>
            </v-container>
            <operating-systems class="pb-2" />
          </v-col>

          <v-col cols="12" lg="4" class="pt-0 pt-lg-3">
            <v-container fluid>
              <v-row>
                <v-col cols="12" sm="6" class="pa-0 pr-sm-2">
                  <traffic-sites class="pb-2" />
                </v-col>
                <v-col cols="12" sm="6" class="pa-0 pl-sm-1">
                  <traffic-premium-sites class="pb-2" />
                </v-col>
              </v-row>
            </v-container>
            <countries />
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import AdFormat from './AdFormat.vue'
  import TrafficType from './TrafficType.vue'
  import OperatingSystems from './OperatingSystems.vue'
  import PricingModel from './PricingModel.vue'
  import Countries from './Countries.vue'
  import InstreamAdType from './InstreamAdType.vue'
  import TrafficSites from './TrafficSites.vue'
  import TrafficPremiumSites from './TrafficPremiumSites.vue'

  export default {
    name: 'SettingsTrafficChart',
    components: {
      InstreamAdType,
      TrafficPremiumSites,
      TrafficSites,
      AdFormat,
      TrafficType,
      OperatingSystems,
      PricingModel,
      Countries,
      CBtn
    },
    data() {
      return {
        updated: false
      }
    },
    computed: {
      ...mapState('trafficChart', ['trafficChartIsLoading']),
      ...mapGetters('trafficChart', ['updateIsAllowed', 'trafficChartPresets'])
    },
    methods: {
      update() {
        this.updated = true
        this.$store.dispatch('trafficChart/update')
      }
    }
  }
</script>
