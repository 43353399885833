<template>
  <c-select
    :id="seleniumIds.SELENIUM_TEST_TRAFFIC_CHART_PRICING_MODEL_SELECT"
    :value="pricingModel"
    :items="pricingModels"
    :disabled="pricingModels.length === 0"
    item-text="label"
    item-value="value"
    :label="$t('traffic_chart.pricing_model')"
    :placeholder="$t('traffic_chart.pricing_model')"
    hide-details
    @change="selectPricingModel($event)"
  />
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex'
  import CSelect from '@clickadilla/components/ui/CSelect.vue'
  import seleniumIds from '@clickadilla/components/constants/selenium-ids.js'

  export default {
    name: 'PricingModel',
    components: { CSelect },
    data() {
      return {
        seleniumIds
      }
    },
    computed: {
      ...mapState('trafficChart', ['pricingModel']),
      ...mapGetters('trafficChart', ['pricingModels'])
    },
    methods: {
      ...mapActions('trafficChart', ['setPricingModel']),
      selectPricingModel(pricingModel) {
        this.setPricingModel(pricingModel)
      }
    }
  }
</script>
